<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'consumers' }" class="button">
        Manage Consumers
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Status" horizontal>
                <b-select placeholder="Is Vacant?" v-model="form.is_vacant">
                    <option value="1">Yes Vacant</option>
                    <option value="0">Not Vacant</option>
                </b-select>
            </b-field>
            <b-field label="Name" message="Consumer name" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. Name"
                required
              />
            </b-field>
            <b-field label="Email" v-if="form.is_vacant==0" message="Consumer's email name" horizontal>
              <b-input
                v-model="form.email"
                placeholder="e.g. name@gmail.com"
                required
              />
            </b-field>
            <b-field label="Emp Number" v-if="form.is_vacant==0" message="Consumer's Emp Number" horizontal>
              <b-input
                v-model="form.emp_num"
                placeholder="e.g. emp num"
              />
            </b-field>
            <b-field label="BU No" message="Consumer's Bill Unit No" horizontal>
              <b-input
                v-model="form.billunitnumber"
                placeholder="e.g. 70542820"
              />
            </b-field>
            <b-field label="Address" horizontal>
              <b-input
                v-model="form.address"
                placeholder="e.g. Address"
              />
            </b-field>
            <b-field label="Quarter" horizontal>
              <b-input
                v-model="form.quarter"
                placeholder="e.g. QTR"
              />
            </b-field>
            <b-field label="Desig" v-if="form.is_vacant==0" message="Consumer's Designation" horizontal>
              <b-input
                v-model="form.designation"
                placeholder="e.g. Designation"
              />
            </b-field>
            <b-field label="Dept" v-if="form.is_vacant==0" message="Consumer's Department Name" horizontal>
              <b-input
                v-model="form.department"
                placeholder="e.g. Department"
              />
            </b-field>
            <b-field label="Region" horizontal>
              <v-select  label="text" placeholder="Search Region" :filterable="false" class="style-chooser" v-model="form.region" :options="regions" @search="onRegionSearch">
                    <template slot="no-options">
                    type to search region ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <b-field label="Code" horizontal>
              <v-select  label="text" placeholder="Search Code" :filterable="false" class="style-chooser" v-model="form.code" :options="codes" @search="onCodeSearch">
                    <template slot="no-options">
                    type to search code ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <b-field label="Category" horizontal>
              <v-select  label="text" placeholder="Search Category" :filterable="false" class="style-chooser" v-model="form.category" :options="categories" @search="onCategorySearch">
                    <template slot="no-options">
                    type to search category ...
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.text }}
                        </div>
                    </template>
                </v-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Consumer Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          @header-icon-click="trashModal(form)"
        >
          <b-field label="Id">
            <b-input :value="form.id" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Email">
            <b-input :value="form.email" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Bill Unit Number" v-if="form.billunitnumber">
            <b-input :value="form.billunitnumber" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Address" v-if="form.quarter">
            <b-input :value="form.address" custom-class="is-static" readonly />
          </b-field>
          <b-field grouped>
            <b-field label="Quarter" v-if="form.quarter">
              <b-input :value="form.quarter" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Designation" v-if="form.designation">
              <b-input :value="form.designation" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Department" v-if="form.department">
              <b-input :value="form.department" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Region">
              <b-input :value="form.region? form.region.text : ''" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Code">
              <b-input :value="form.code? form.code.text : ''" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Category">
              <b-input :value="form.category? form.category.text : ''" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field label="Emp No">
            <b-input :value="form.emp_num" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created At">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Updated At">
            <b-input
              :value="form.updated_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import { apiService } from '@/services'

export default {
  name: 'ConsumerForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      isProfileExists: false,
      categories: [], // Used in options
      regions: [], // Used in options
      codes: [] // Used in options
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.name : 'New Consumer'
      return ['Admin', 'Consumers', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? this.form.name : 'Create Consumer'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit Consumer' : 'New Consumer'
    },
    ...mapState('consumer', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('consumer', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    getClearFormObject () {
      return {
        id: null,
        name: null,
        emp_num: null,
        designation: null,
        department: null,
        region: null,
        category: null,
        code: null,
        is_vacant: 0,
        billunitnumber: null,
        quarter: null,
        address: null,
        created_at: null,
        updated_at: null
      }
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'consumers' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          // console.log(this.item)
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'consumer.new' })
          }
        })
      }
    },
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) this.$router.push({ name: 'consumers' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    },
    onCategorySearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'categories', search, this)
      }
    },
    onRegionSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'regions', search, this)
      }
    },
    onCodeSearch (search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, 'codes', search, this)
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350)
  }
}
</script>
